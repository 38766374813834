'use client'

import { buttonVariants } from '@paladise/ui/components/ui/button'
import { cn } from '@paladise/ui/lib/utils'
import DownloadLink from 'components/DownloadLink'
import { sidebarLinks } from 'config/constants'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { tv } from 'tailwind-variants'
import { HASH_KEY } from 'utils/hooks/useHash'

const styles = tv({
  slots: {
    link: 'text-label-l1 hover:bg-background-2nd text-mainText group flex min-w-0 items-center py-[16px]',
    icon: 'min1280:mr-4 mx-auto shrink-0 [--icon-size:32px]',
    label:
      'min1280:inline-block group-data-[active="true"]:text-title hidden min-w-0 shrink grow basis-0 text-ellipsis',
  },
})

const LinkWrapper = (props: {
  href: string
  className?: string
  'data-active'?: boolean
  children: React.ReactNode
}) => {
  if (props.href === `#${HASH_KEY.DOWNLOAD_APP}`) {
    return <DownloadLink {...props} />
  }
  return <Link {...props} />
}

function SidebarMenu() {
  const { link, icon, label } = styles()
  const pathname = usePathname()
  const t = useTranslations()

  const links = sidebarLinks(t)

  return (
    <nav>
      {links.map(({ tabIcon, title, href }) => (
        <LinkWrapper
          key={title}
          href={href}
          className={link()}
          data-active={pathname.startsWith(href)}
        >
          <span className={icon({ className: tabIcon })} aria-hidden></span>
          <span className={label()}>{title}</span>
        </LinkWrapper>
      ))}
      <DownloadLink
        className={cn(
          buttonVariants({ className: 'max-min1280:hidden my-2 w-full' }),
        )}
      >
        {t('create')}
      </DownloadLink>
    </nav>
  )
}

export default SidebarMenu
