'use client'

import { Separator } from '@paladise/ui/components/ui/separator'
import SidebarFooter from './SidebarFooter'
import SidebarMenu from './SidebarMenu'
import UserInfo from './UserInfo'
import { CDN_URL } from '@paladise/config/constants'
import Logo from 'components/Logo'

function Sidebar() {
  return (
    <div className="min1280:w-[240px] min768:flex border-r-glass-separators hidden h-dvh w-[80px] shrink-0 flex-col justify-between overflow-clip overflow-y-auto border-r p-3 pt-4">
      <div>
        <div className="flex h-[40px] items-center">
          <Logo />
        </div>
        <Separator
          orientation="horizontal"
          className="bg-glass-separators my-2"
        />
        <SidebarMenu />
      </div>

      <div className="mt-auto">
        <UserInfo />
        <hr className="bg-glass-separators mt-2" />
        <SidebarFooter />
      </div>
    </div>
  )
}

export default Sidebar
