'use client'

import { NO_PHOTO_PATH } from '@paladise/config/constants'
import { Button } from '@paladise/ui/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@paladise/ui/components/ui/dropdown-menu'
import { Skeleton } from '@paladise/ui/components/ui/skeleton'
import { cn } from '@paladise/ui/lib/utils'
import { ROUTE_PATH } from 'config/routerPath'
import { logout } from 'features/auth/actions/logout'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useState, useTransition } from 'react'
import { useAccountStore } from 'store/account-store'
import { usePopupStore } from 'store/popup-store'
import { session } from 'store/server-context/static'

interface Prop {
  className?: string
}

function UserInfo({ className }: Prop) {
  const [isLoading, startTransition] = useTransition()
  const [isOpen, setIsOpen] = useState(false)
  const t = useTranslations()
  const { push } = useRouter()
  const user = useAccountStore.use.userInfo().data?.user
  const loading = useAccountStore.use.userInfo().loading
  const setAuthPopup = usePopupStore.use.setAuthPopup()

  if (session.guest)
    return (
      <Button
        onClick={() => {
          setAuthPopup(true)
        }}
        className="bg-background-2nd border-label-l3 text-subtitle text-label-l1 flex h-10 w-full items-center justify-center gap-1 rounded-[60px] border p-0"
      >
        <span className="i-ps-leading min1280:size-[18px] size-[20px] shrink-0" />
        <span className="min1280:block hidden">{t('login')}</span>
      </Button>
    )

  const handleLogout = () => {
    startTransition(async () => {
      await logout()
    })
  }

  return (
    <div
      className={cn(
        'flex w-full cursor-pointer items-center justify-between py-2',
        className,
      )}
    >
      <Link className="flex items-center" href={ROUTE_PATH.ACCOUNT.INDEX}>
        <img
          src={user?.image_url || NO_PHOTO_PATH}
          className="mr-3 size-8 shrink-0 rounded-full object-cover"
          alt="user headshot"
        />
        <div className="min1280:block hidden w-[120px]">
          {loading ? (
            <Skeleton className="h-6 w-full" />
          ) : (
            <p className="text-label-l1 text-callout truncate">{user?.name}</p>
          )}
        </div>
      </Link>

      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="text" className="-mr-2 p-0">
            <span className="i-ps-more_vertical [--icon-size:24px]" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="top"
          align="end"
          className="h-12 w-[148px] p-0"
        >
          <DropdownMenuItem
            onSelect={handleLogout}
            disabled={isLoading}
            asChild
          >
            <Button
              variant="text"
              className="text-label-l1 text-body h-full w-full justify-start rounded-[12px] p-[14px]"
              loading={isLoading}
              loadingClassName="text-label-l1"
            >
              {t('log_out')}
            </Button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default UserInfo
