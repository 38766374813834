'use client'

import { TRACKER_EVENTS } from '@paladise/tracker/constants/event'
import { cn } from '@paladise/ui/lib/utils'
import LanguageSelect from 'components/LanguageSelect'
import { ROUTE_PATH } from 'config/routerPath'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { env } from 'store/server-context/static'
import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    container: 'text-label-l2 text-caption relative py-4 font-semibold',
    mainItem: 'min1280:inline hidden',
    item: 'text-label-l3 flex flex-shrink-0 flex-grow-0 basis-auto text-center',
    list: '[&>a]:text-label-l3 flex w-full flex-wrap items-center gap-x-[12px] gap-y-[4px]',
  },
})

type LinkItem = {
  text: string
  href: string
  trackerEventName: string
  className?: string
}

const Item = ({ text, href, trackerEventName, className }: LinkItem) => {
  return (
    <li className={className}>
      <Link
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-tracker-click={trackerEventName}
      >
        {text}
      </Link>
    </li>
  )
}

const SidebarFooter = ({ row }: { row?: LinkItem[] }) => {
  const { mainItem, container, item, list } = styles()
  const t = useTranslations()

  const mainItems = [
    {
      text: t('about'),
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.ENTRY.ABOUT}`,
      trackerEventName: TRACKER_EVENTS.text_about_click,
    },
    {
      text: t('common.Contact'),
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.ENTRY.CONTACT.INDEX}`,
      trackerEventName: '',
    },
    {
      text: t('help_center'),
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.HELP.INDEX}`,
      trackerEventName: TRACKER_EVENTS.text_helpcenter_click,
    },
    {
      // TODO: translation
      text: 'Product',
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.ENTRY.PRODUCT}`,
      trackerEventName: '',
    },
  ]

  const baseRow = [
    {
      text: t('ethical_rules'),
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.TERMS.PALUP_RULES}`,
      trackerEventName: TRACKER_EVENTS.text_userrules_click,
    },
    {
      text: t('user_terms_of_service'),
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.TERMS.USER_TERMS_OF_SERVICE}`,
      trackerEventName: TRACKER_EVENTS.text_termsofservice_click,
    },
    {
      text: t('privacy_cookies'),
      href: `${env.CONSUMER_BASE_URL}${ROUTE_PATH.TERMS.PRIVACY_POLICY}`,
      trackerEventName: TRACKER_EVENTS.text_privacypolicy_click,
    },
  ]

  return (
    <div className={container()}>
      <ul className={list()}>
        {mainItems.map((el, i) => (
          <Item key={el.text} {...el} className={cn(item(), mainItem())} />
        ))}
        {baseRow.map((el, i) => (
          <Item key={el.text} {...el} className={cn(item(), mainItem())} />
        ))}
        {row?.map((el, i) => (
          <Item key={el.text} {...el} className={cn(item(), mainItem())} />
        ))}
        <li className={cn(item())}>
          <LanguageSelect className="text-caption mb-1 w-auto min-w-0" />
        </li>
        <li className={item()}>
          <div className="min1280:flex-row flex flex-col">
            <img
              src="/images/palup_app_icon.svg"
              alt="palup_app_icon"
              className={cn('mb-1 mr-1 inline-block size-4')}
            />
            ©{new Date().getFullYear()}
            <span className="min1280:inline-block mb-1 ml-1 hidden">PalUp</span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SidebarFooter
